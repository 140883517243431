import { StaticQuery, graphql } from "gatsby";

// Actions
import {
  initializeContentfulProductBundleData,
  initializeContentfulProductData,
} from "../../store/staticData/actions";
import { addPlansFromGraphQL } from "../../store/plan/actions";
import { addProductsFromGraphQL } from "../../store/product/actions";
import { addPromotionsFromGraphQL } from "../../store/promotion/actions";
import { addProductGroupsFromGraphQL } from "../../store/product-group/actions";
import { addProductOffersFromGraphQL } from "../../store/product-offer/actions";
import { addProductOfferPlansFromGraphQL } from "../../store/product-offer-plan/actions";

const componentQuery = graphql`
  query initializeStoreQuery {
    allContentfulProduct {
      nodes {
        node_locale
        contentful_id
        name {
          name
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        stockStatus
        sku
        contentfulId: contentful_id
        slug
        headerDescription
        productSubhead
        productValueProps {
          content
        }
        shortDescription
        summary
        supplementFacts {
          nutrients {
            label
            dosage
            dv
          }
          ingredients {
            label
            dosage
            dv
          }
          notations {
            label
          }
          otherIngredients
          notationReferences
          attributions {
            label
          }
          simpleIngredients {
            name
            dosage
          }
        }
        heroFeaturedImages {
          title
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 555
            quality: 90
          )
          description
        }
        heroZoomImages: heroFeaturedImages {
          title
          gatsbyImageData(layout: CONSTRAINED, width: 1920, quality: 90)
          description
        }
        valueProps {
          name
          icon {
            file {
              url
              fileName
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
        upsellThumbnail {
          gatsbyImageData(layout: FIXED, width: 204, height: 204, quality: 90)
          description
        }
        alternateThumbnail {
          gatsbyImageData(layout: FIXED, width: 204, height: 204, quality: 90)
          description
        }
        cartImage {
          gatsbyImageData(layout: CONSTRAINED, width: 200, quality: 100)
          description
        }
        socialImage {
          file {
            url
          }
        }
        shopImages {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 280
            quality: 100
          )
          description
        }
        upsellProductOffer {
          slug
          ritualProductOffer {
            product_offer_id
            slug
          }
        }
      }
    }
    allRitualProduct {
      nodes {
        id: product_id
        product_id
        contentful_id
        attributes
        relationships
      }
    }
    allRitualProductGroup {
      nodes {
        id: product_group_id
        product_group_id
        attributes
        relationships
      }
    }
    allRitualPlan {
      nodes {
        id: plan_id
        plan_id
        attributes
        relationships
      }
    }
    allRitualPromotion {
      nodes {
        id: promotion_id
        promotion_id
        attributes
        relationships
      }
    }
    allRitualProductOffer {
      nodes {
        id: product_offer_id
        product_offer_id
        attributes
        relationships
      }
    }
    allRitualProductOfferPlan {
      nodes {
        id: product_offer_plan_id
        product_offer_plan_id
        attributes
        relationships
      }
    }
    allContentfulProductBundle {
      nodes {
        title
        slug
        node_locale
        discountValue
        shopImages {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 555
            quality: 90
          )
        }
        flag
        shopDescription
        products {
          stockStatus
          sku
          name {
            name
            childMarkdownRemark {
              html
            }
          }
          shortDescription
          supplementFacts {
            id
            simpleIngredients {
              dosage
              name
              description
            }
          }
        }
        ritualProductOffer {
          product_offer_id
        }
      }
    }
  }
`;

const initializeStore = (props) => (
  <StaticQuery
    query={componentQuery}
    render={(data) => {
      const { children, store } = props;
      const contentfulProductData = data.allContentfulProduct.nodes;
      const contentfulProductBundleData = data.allContentfulProductBundle.nodes;
      const productData = data.allRitualProduct.nodes;
      const productGroupData = data.allRitualProductGroup.nodes;
      const planData = data.allRitualPlan.nodes;
      const promotionData = data.allRitualPromotion.nodes;
      const productOfferData = data.allRitualProductOffer.nodes;
      const productOfferPlanData = data.allRitualProductOfferPlan.nodes;

      store.dispatch(initializeContentfulProductData(contentfulProductData));
      store.dispatch(
        initializeContentfulProductBundleData(contentfulProductBundleData),
      );
      store.dispatch(addProductsFromGraphQL(productData));
      store.dispatch(addProductGroupsFromGraphQL(productGroupData));
      store.dispatch(addPlansFromGraphQL(planData));
      store.dispatch(addPromotionsFromGraphQL(promotionData));
      store.dispatch(addProductOffersFromGraphQL(productOfferData));
      store.dispatch(addProductOfferPlansFromGraphQL(productOfferPlanData));

      return <>{children}</>;
    }}
  />
);
export default initializeStore;
