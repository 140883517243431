import { Children, cloneElement, Component } from "react";
import styled from "styled-components";

// Services
import cartService from "../services/cart";

// Utils
import { currentPageData } from "../utils/currentPageData";

// Add global styles
import "../styles/index.scss";

// Shared Components across site
import Navigation from "../components/global/Navigation";
import FlyoutCart from "../components/cart/FlyoutCart";
import Banners from "../components/banners/Banners";
import Footer from "../components/global/Footer";
import SegmentConsentManager from "../components/segment/SegmentConsentManager";
import LocaleSuggester from "../components/global/LocaleSuggester";

import { defineCustomElements } from "@ritual/essentials-for-react";

// Store
import { connect } from "react-redux";
import { getStore } from "../store/createStore";

// Actions
import { appLoaded } from "../store/app/actions";

// Selectors
import cartProductSelectors from "../store/cart-product/selectors";
import {
  CustomerScope,
  hasScope,
  isAuthenticated,
  logout,
} from "../utils/authentication";

import "@ritual/essentials-for-web/dist/stencil-library/stencil-library.css";

defineCustomElements();

const SiteRoot = styled.div.attrs({ className: "content-hub-page" })`
  padding-top: ${(p) => {
    // Account for offset and 4px loading bar at top of page
    return p.topOffset ? `${p.topOffset - 4}px` : "0";
  }} !important;
  overflow-x: hidden;

  &:focus {
    outline: none;
  }
`;

export class RitualBase extends Component {
  constructor(props) {
    super(props);

    if (isAuthenticated() && !hasScope(CustomerScope)) {
      logout(false);
    }

    this.updatePageData = this.updatePageData.bind(this);

    this._bufferedOffset = null;
    this._isMounted = false;
  }

  // Triggers on first load
  componentDidMount() {
    this._isMounted = true;
    if (typeof window !== "undefined" && "performance" in window) {
      performance.mark("application-loaded");
    }

    getStore().dispatch(appLoaded());

    this.loadCheckHeadings();
  }

  componentDidUpdate() {
    console.log("State or prop change");
  }

  componentWillUnmount() {
    window.clearInterval(this.cyberMondayInterval);
    this._isMounted = false;
  }

  async loadCheckHeadings() {
    if (
      process.env.GATSBY_ACTIVE_ENV == "development" &&
      process.env.GATSBY_CHECK_HEADINGS
    ) {
      const { default: checkHeadings } = await import("../utils/headings");
      checkHeadings();
    }
  }

  setOffset(height = 0) {
    if (!this._isMounted) {
      this._bufferedOffset = height;
      return;
    }

    this._bufferedOffset = null;
    this.setState({
      offset: height,
    });
  }

  updatePageData(data) {
    if (data.label) {
      currentPageData.label = data.label;
    }
  }

  renderNavigation() {
    const { location } = this.props;

    return (
      <Navigation>
        <Banners location={location} />
      </Navigation>
    );
  }

  render() {
    const { children, location, offset, showUSPNotice } = this.props;

    const childrenWithProps = Children.map(children, (child) =>
      cloneElement(child, {
        updatePageData: this.updatePageData,
        offset,
      }),
    );

    // Strip all "/" from the beginning and end of the path string.
    const strippedPath = this.props.location.pathname.replace(/^\/+|\/+$/g, "");

    // Hiding nav on certain pages
    const pagesWithoutNav = ["build-a-bundle", "checkout"];
    const hideNav = !!pagesWithoutNav.find((p) => strippedPath === p);

    // Hiding footer on certain pages
    const pagesWithoutFooter = ["refer50plus", "build-a-bundle", "checkout"];
    const hideFooter = !!pagesWithoutFooter.find((p) => strippedPath === p);
    const displaySlimFooter = children.props.pageContext.slimFooter || false;

    return (
      <>
        {!hideNav && this.renderNavigation()}

        <SiteRoot topOffset={offset} id="main">
          {childrenWithProps}
        </SiteRoot>

        {!hideFooter && (
          <Footer
            location={location}
            displaySlimFooter={displaySlimFooter}
            showUSPNotice={showUSPNotice}
          />
        )}
        <div id="modal-root">
          <FlyoutCart />
          <LocaleSuggester />
        </div>
        <div id="zoom-root"></div>
        <SegmentConsentManager />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { navigation, banners } = state;
  return {
    offset: navigation.offset,
    showUSPNotice: state.navigation.showFooterUSPNotice,
    cartQuantity: cartProductSelectors.activeCartProductQuantity(state),
  };
};

const componentWithState = connect(mapStateToProps)(RitualBase);

export default componentWithState;
