import intl from "../services/intl";
import { getCookie } from "./cookies";
import { getUserTraits } from "./currentUser";

function identify(id, traits, options) {
  const attributes = _getIdentifyTraits(traits);

  if (!window.analytics || !typeof window.analytics.identify === "function") {
    return;
  }

  if (id === undefined) {
    window.analytics.identify(attributes);
  }

  if (options) {
    window.analytics.identify(id, attributes, options);
  } else {
    window.analytics.identify(id, attributes);
  }
}

function page(pageLabel, properties = {}) {
  if (!window.analytics || !typeof window.analytics.page === "function") {
    return;
  }

  _parseOptions(properties, {
    addTikTokEventId: true,
  });

  window.analytics.page(pageLabel, properties);
}

function track(event, properties = {}, options, callback) {
  let trackOptions = _parseOptions(properties, options);

  if (!window.analytics || !typeof window.analytics.track === "function") {
    return;
  }

  const trackArgs = [event, properties, trackOptions];

  if (callback) {
    trackArgs.push(callback);
  }

  window.analytics.track(...trackArgs);
}

function _getIdentifyTraits(attrs) {
  const userTraits = getUserTraits();

  if (!attrs.first_name) {
    attrs.first_name = userTraits.firstName;
  }
  if (!attrs.last_name) {
    attrs.last_name = userTraits.lastName;
  }
  if (!attrs.email) {
    attrs.email = userTraits.email;
  }

  attrs.locale = intl.locale;

  return attrs;
}

function _parseOptions(properties, options = {}) {
  let traits;
  let trackOptions = {};

  if (options.addTraitsToContext) {
    if (!traits) traits = getUserTraits();
    trackOptions.context = {
      traits,
    };
  }

  if (options.addMarketingPreference) {
    // Pass in marketing preference to traits for User Signup
    traits.marketingPreference = properties.marketingPreference;
    trackOptions.context = {
      traits,
    };
  }

  if (options.addEmail) {
    // Only grab the user traits if they were not grabbed in the option above.
    if (!traits) traits = getUserTraits();
    if (traits.email) {
      properties.email = traits.email;
    }
  }

  if (options.addFacebookCookies) {
    const fbp = getCookie("_fbp");
    const fbc = getCookie("_fbc");
    if (fbp) {
      properties.fbp = fbp;
    }
    if (fbc) {
      properties.fbc = fbc;
    }
  }

  if (options.addTikTokEventId) {
    _addTikTokEventId(properties);
  }

  // If the segmentOptions property is passed, append those options to the top
  // level tracking options.
  if (options.segmentOptions) {
    trackOptions = { ...trackOptions, ...options.segmentOptions };
  }

  _parseIntegrations(trackOptions);

  // Once all the options are parsed, update the options that are passed to the
  // analytics.track calls.
  return Object.keys(trackOptions).length ? trackOptions : undefined;
}

function _addTikTokEventId(properties) {
  properties.ttei = new Date().getTime() + "" + Math.random();
}

function _parseIntegrations(trackOptions) {
  const { integrations } = trackOptions;

  if (!window.analytics || !integrations) {
    return;
  }

  const userIntegrations = { ...window.analytics.integrations };

  // If All is false, all existing integrations preferences should be
  // overwritten to false. This only applies to the current event being tracked,
  // and does not change the persisted integration preferences for the user.
  const allValue = integrations.All;

  Object.keys(userIntegrations).forEach((key) => {
    const userValue = userIntegrations[key];
    const optionsValue = integrations[key];
    let mergedValue = true;

    // Do not override the Segment.io integration.
    if (key === "Segment.io") return;

    if (userValue === false) {
      mergedValue = false;
    } else if (optionsValue !== undefined) {
      mergedValue = optionsValue;
    } else {
      mergedValue = allValue !== undefined ? allValue : userValue;
    }

    trackOptions.integrations[key] = mergedValue;
  });
}

const metrics = {
  identify,
  page,
  track,
};

export default metrics;
